.h-wrapper {
  color: white;
}

.h-wrapper h1 {
  font-size: 25px;
  font-weight: bold;
  font-family: 'kanit', 'sans-serif';
  font-style: italic;
}

.h-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.h-menu {
  display: flex;
  gap: 2rem;
}

.h-menu a,
.h-menu button {
  color: white;
  text-decoration: none;
  &:hover {
    color: orange;
  }
}

.menu-icon {
  display: none;
}

.logo-container img {
  max-width: 100%; /* Make the logo responsive */
  height: auto;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
    order: 3; /* Move the menu icon to the bottom in the visual order */
    cursor: pointer;
  }

  .h-menu {
    display: none;
    position: absolute;
    top: 4rem;
    right: 1rem;
    background-color: black;
    flex-direction: column;
    font-weight: 500;
    gap: 1rem; /* Adjust spacing between menu items */
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    transition: all 300ms ease-in;
    order: 2; /* Move the menu items above the menu icon in the visual order */
  }

  .h-menu a,
  .h-menu button {
    color: white;
    &:hover {
      color: orange;
    }
  }
}

@media (max-width: 360px) and (max-height: 740px) {
  /* Add your specific styles for 360x740 screen size here */
  .h-menu {
    top: 2rem; /* Adjust the top position for the menu items */
    right: 0.5rem; /* Adjust the right position for the menu items */
  }
}
